import { GEO_NAMES } from '@bridebook/toolbox/src/search-suppliers/location-helpers';
import { CustomAutocompletePrediction, GooglePlace } from '../types';

/**
 * User types in the location search input and selects an item. Details about the location
 * passed here to extract text that will be placed in the input.
 *
 * The function transforms Google Place object to text:
 * `{
 *   address_components: [
 *     {
 *       long_name: 'Hauts-de-France',
 *       short_name: 'Hauts-de-France',
 *       types: ['administrative_area_level_1', 'political'],
 *     },
 *     {
 *       long_name: 'France',
 *       short_name: 'FR',
 *       types: ['country', 'political'],
 *     },
 *   ],
 *   formatted_address: 'Hauts-de-France, France',
 *   name: 'Hauts-de-France',
 *   types: ['administrative_area_level_1', 'political'],
 * }`
 *
 * results in placing `Hauts-de-France` text to the input.
 * @param place
 */
export function extractPlaceName(place: GooglePlace): string {
  let placeName = place.name;
  if ((place as CustomAutocompletePrediction).area) {
    return (place as CustomAutocompletePrediction).area?.replace(/,/g, '').trim() || '';
  }
  const country = place.address_components?.find(isCountry);
  if (
    place.vicinity &&
    place.vicinity !== place.name &&
    place.vicinity !== (country?.long_name || GEO_NAMES.UK)
  ) {
    placeName = `${place.name} ${place.vicinity}`;
  } else {
    const town = place.address_components?.find(isTown);
    if (town) {
      if (place.name !== town.long_name) {
        placeName = `${place.name} ${town.long_name}`;
      }
    } else {
      placeName = getAdminAreaName(place.address_components) || place.formatted_address;
    }
  }
  return placeName?.replace(/,/g, '').trim() || '';
}

/**
 * Extracts and returns the most specific administrative or political area name
 * from a given set of address components.
 *
 * @param addressComponents - An array of address components as provided by the Google Places API.
 *
 * @returns The most specific and relevant administrative or political area name
 * from the provided address components.
 * The returned string is formatted according to specific business rules,
 * such as replacing "County" with "Co." for certain administrative areas.
 *
 * @remarks
 * The function prioritizes the address components in the following order:
 * 1. Political Area (if no Administrative Areas are present)
 * 2. Administrative Area Level 4
 * 3. Administrative Area Level 3
 * 4. Colloquial Area
 * 5. Administrative Area Level 2
 * 6. Administrative Area Level 1
 *
 */
const getAdminAreaName = (
  addressComponents: GooglePlace['address_components'],
): string | undefined => {
  const colloquialArea = addressComponents?.find(isColloquialArea);
  const adminArea2 = addressComponents?.find(isAdminArea(2));
  const adminArea1 = addressComponents?.find(isAdminArea(1));
  const adminArea3 = addressComponents?.find(isAdminArea(3));
  const adminArea4 = addressComponents?.find(isAdminArea(4));

  const politicalAreaWithNoAdminArea = addressComponents?.find(isPoliticalAreaWithNoAdminAreas);

  // Sometimes the most specific area is a political area without any administrative areas
  if (politicalAreaWithNoAdminArea?.long_name) {
    return politicalAreaWithNoAdminArea.long_name;
  }
  if (adminArea4?.long_name) {
    return adminArea4.long_name;
  }
  if (adminArea3?.long_name) {
    return adminArea3.long_name;
  }
  if (colloquialArea?.long_name) {
    return colloquialArea?.long_name;
  }
  if (adminArea2?.long_name) {
    return adminArea2.long_name;
  }
  if (adminArea1?.long_name) {
    // IE business decision to have "Co. Dublin" instead of "County Dublin". It works consistently with Google API
    return adminArea1.long_name.replace('County', 'Co.');
  }
};

/**
 * Checks if an address component is a colloquial area.
 * @returns True if the component is a colloquial area, false otherwise.
 * @param comp
 */
const isColloquialArea = (comp: { types: string[] }): boolean =>
  comp.types?.includes('colloquial_area') && comp.types?.includes('political');

/**
 * Checks if an address component is an administrative area of a specified level.
 * @param level - The administrative area level to check.
 * @returns A function that takes an address component and returns true if it is an
 * administrative area of the specified level, false otherwise.
 */
const isAdminArea =
  (level: 1 | 2 | 3 | 4 | '') =>
  (comp: { types: string[] }): boolean =>
    comp.types?.includes(`administrative_area_level_${level}`);

/**
 * Checks if an address component is only a political area. Sometimes the most specific area is a political area
 * @returns True if the component is only a political area, false otherwise.
 * @param comp
 */
const isPoliticalAreaWithNoAdminAreas = (comp: { types: string[] }): boolean =>
  comp.types.length === 1 && comp.types.includes('political');

/**
 * Checks if an address component is a town.
 * @returns True if the component is a town, false otherwise.
 * @param comp
 */
const isTown = (comp: { types: string[] }): boolean =>
  comp.types?.includes('postal_town') || comp.types?.includes('locality');

/**
 * Checks if an address component is a country.
 * @returns True if the component is a country, false otherwise.
 * @param comp
 */
const isCountry = (comp: { types: string[] }): boolean =>
  comp.types?.includes('country') && comp.types?.includes('political');
