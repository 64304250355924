import { deleteField } from 'firebase/firestore';
import {
  equals,
  filter,
  head,
  is,
  isEmpty,
  map,
  mergeWith,
  omit,
  prop,
  sortBy,
  values,
} from 'ramda';
import { FocusEvent, FormEvent, ReactNode } from 'react';
import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
  useStore as _useStore,
} from 'react-redux';
import { TStore } from 'store/store';
import { getDayOrdinal } from '@bridebook/toolbox/src/datepicker/get-day-ordinal';
import type { Slug } from '@bridebook/toolbox/src/types';
import { format } from 'lib/i18n/utils/get-date-fns-format';
import Shortlisted from 'lib/shortlist/shortlisted';
import { getI18n } from './i18n/getI18n';
import { IApplicationState, IConnectedDispatch, IReducersImmer } from './types';

export { shallowEqual } from './utils/shallowEqual';

export {
  isNumberKey,
  isNumberKeyBool,
  menuFilter,
  getDefaultImage,
  preventSubmit,
  colors,
  hexToRgb,
  getCarouselDirection,
  onlyNumberKey,
} from './utils/ui';

export { getSocialShareUTM } from './utils/seo';

export {
  getSupplierFriendlyUrlId,
  toUrlQuery,
  isURL,
  extractRootURL,
  getVideoIframeUrl,
  getCanonicalUrl,
  prepareObjForElastic,
  makeSearchPathname,
  makeSearchQuery,
  getLocationLandingUrl,
  isSupplierPage,
  isSupplierGalleryPage,
  isArticlePage,
  isToolPage,
  createQs,
} from './utils/url';

export const imgixBaseURL = 'https://images.bridebook.com';
export const imgixBBUsersURL = 'https://bridebook-users.imgix.net';

/**
 * Function `truncate` truncates the string and adds ellipsis if string is
 * longed than specified limit amount
 *
 * @function truncate
 * @param {String, Number} - String and limit
 * @returns {String} - returns truncated or original string
 */
export const truncate = (text: string, lim?: number) => {
  const value = text || '';
  const limit = lim ? lim : value.length;
  return value.length > limit ? `${text.substring(0, limit - 3)}…` : text;
};

export const sortByCreatedAt = <T extends { createdAt: number }>(item: T[]) =>
  sortBy(prop('createdAt'), item);
export const sortByUpdatedAt = sortBy(prop('updatedAt'));
export const sortByOrder = sortBy(prop('order'));

export { nestedToParent } from './utils/url';

/**
 * Function `getExcerpt` shorten the text to specific word count
 *
 * @function getExcerpt
 * @param {String, Number} - input text, max word count
 * @returns {String} - excerpt text
 */
export const getExcerpt = (text: string, maxWords: number) =>
  text
    .toString()
    .replace(new RegExp(`(([^\\s]+\\s\\s*){${maxWords}})(.*)`), '$1…')
    .trim();

export const filterByType = <T extends { type: Slug }>(items: T[], category: Slug): T[] =>
  filter((item: T) => item['type'] === category, items);

export const isBooked = <T extends { booked: boolean }>(items: T[]) =>
  filter((item) => item.booked, items);

export const getBooked = <T extends { type: Slug; booked: boolean }>(
  shortlist: Record<string, T> | T[],
  slug: Slug,
): T | undefined =>
  head(filter((item: T) => item.type === slug && item.booked)(values(shortlist) as T[]));

export const getShortlisted = <T extends { type: Slug }>(shortlist: T[], slug: Slug) => {
  const items = filterByType<T>(shortlist, slug);
  return { ...Shortlisted, title: slug, type: slug, count: items.length };
};

export const isEmptyCategory = (scrapbook: any[], slug: Slug) =>
  isEmpty(filter((item) => item.type === slug, scrapbook));

/**
 * Function `toTitleCase` format string to Title Case
 *
 * @function toTitleCase
 * @param {String} - input string
 * @returns {String} - returns string in Title Case
 */
export const toTitleCase = (str: string) => {
  if (typeof str !== 'string') return '';
  const smallWords =
    /^(a|an|upon|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
  const rule = (match: string, index: number, title: string) => {
    if (
      index > 0 &&
      index + match.length !== title.length &&
      match.search(smallWords) > -1 &&
      title.charAt(index - 2) !== ':' &&
      (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
      title.charAt(index - 1).search(/[^\s-]/) < 0
    ) {
      return match.toLowerCase();
    }
    if (match.substr(1).search(/[A-Z]|\../) > -1) {
      return match;
    }
    return match.charAt(0).toUpperCase() + match.substr(1);
  };
  return str && str.replace ? str.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, rule) : '';
};

/**
 * Function `deepMerge` deep merge object
 *
 * @function deepMerge
 * @param {Object, Object}
 * @returns {Object} - merged object
 */
// @ts-ignore FIXME
export const deepMerge = (a, b) =>
  is(Object, a) && is(Object, b) ? mergeWith(deepMerge, a, b) : b;

/**
 * Function `getFormattedDate` return date string in specific format
 *
 * @function getFormattedDate
 * @returns {String} - returns date time string
 */
export const getFormattedDate = ({
  date,
  shortMonth,
  noDay,
}: {
  date: string | Date | number;
  shortMonth?: boolean;
  noDay?: boolean;
}) => {
  const i18n = getI18n();
  const d = new Date(date);
  const formatStr =
    (noDay ? '' : `${getDayOrdinal(i18n.language)} `) + (shortMonth ? 'LLL' : 'LLLL') + ' y';

  return format(d, formatStr);
};

export const mapMonthToSeason = (month: number) => {
  let season = null;
  switch (month) {
    case 11:
    case 0:
    case 1:
      season = 'winter';
      break;
    case 2:
    case 3:
    case 4:
      season = 'spring';
      break;
    case 5:
    case 6:
    case 7:
      season = 'summer';
      break;
    case 8:
    case 9:
    case 10:
      season = 'autumn';
      break;
  }
  return season;
};

export const mapDayToWeekday = (day: number) => {
  let weekDay = null;
  switch (day) {
    case 0:
      weekDay = 'Sunday';
      break;
    case 1:
    case 2:
    case 3:
    case 4:
      weekDay = 'Mon-Thurs';
      break;
    case 5:
      weekDay = 'Friday';
      break;
    case 6:
      weekDay = 'Saturday';
      break;
  }
  return weekDay;
};

/**
 * Identify if current useragent string belongs to
 * embedded browser inside of Facebook mobile app
 * @method isFacebookApp
 * @returns {Boolean}
 */
// stackoverflow.com/a/33997042/233902
export const isFacebookApp = () => {
  const ua = window.navigator.userAgent || window.navigator.vendor;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
};

/**
 * Identify if current useragent string belongs to
 * embedded browser inside of bridebook Cordova App
 * @method isCordovaApp
 * @returns {Boolean}
 */
export const isCordovaApp = () => {
  const ua = navigator ? navigator.userAgent || navigator.vendor : '';
  // @ts-ignore FIXME
  return ua.indexOf('Cordova') > -1;
};

/**
 * Return event.target.value of an event passed from HTMLInputElement
 * @param event: SyntheticEvent<HTMLInputElement>
 */
export const getEventValue = (
  event: FocusEvent<ReactNode | HTMLElement> | FormEvent<ReactNode | HTMLElement>,
) => (event.target as HTMLInputElement).value;

/**
 * Get next "order" value for sorting custom objects like Cost, Guest etc.
 * @param i - optional param to differentiate values
 */
export const getNextOrder = (i = 0) => Date.now() / 1000 - 1546300800 + i / 1000;

/**
 * Clean timestamp data from a single object
 * @param data
 */
// @ts-ignore FIXME
export const cleanTimestamps = <T>(data: T): T => omit(['createdAt', 'updatedAt'])(data);

/**
 * Clean timestamp data from an Array-of-Objects or an Object-of-Objects
 * @param data
 */
// @ts-ignore FIXME
export const mapCleanTimestamps = <T>(data: T): T => map(omit(['createdAt', 'updatedAt']), data);

/**
 * Replace fields containing empty values with FieldValue.delete function.
 * If an object provided is null or undefined it'll also be replaced with delete.
 * @param data - a single property of a Document
 */
export const removeEmptyProps = <T>(data: T): T => {
  if (!data) {
    // @ts-ignore FIXME
    return deleteField();
  }
  // @ts-ignore FIXME
  return map((val) => (isEmpty(val) ? deleteField() : val), data);
};

/**
 * Used with React.memo
 */
export const arePropsEqual = <T extends object>(prevProps: T, nextProps: T) =>
  equals(prevProps, nextProps);

/**
 * Creates a map object with the actions used by the reducer as keys, and
 * `true` as value (to avoid having those functions in memory). This map object
 * is used in some reducers to decide whether to call immer's `produce`
 * function or not.
 */
export const getReducerActions = <State>(reducer: IReducersImmer<State>) =>
  // @ts-expect-error
  Object.keys(reducer(null) || {}).reduce<Record<string, true | undefined>>((acc, keyName) => {
    acc[keyName] = true;
    return acc;
  }, {});

/**
 * Typed useDispatch
 */
export const useDispatch = () => _useDispatch<IConnectedDispatch['dispatch']>();

/**
 * Typed useSelector
 */
export const useSelector: TypedUseSelectorHook<IApplicationState> = _useSelector;

/**
 * Typed useStore
 */
export const useStore = _useStore as () => TStore;

export const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Returns provided value if it's not greater than max, returns max otherwise
 */
export const noGreaterThan = (value: number, max: number) => (value > max ? max : value);

export const noopAction = () => ({ type: 'NOOP' });

/**
 * Converts number of days/hours/minutes etc. to milliseconds
 */
export const toMillis = (count: number, from: 'week' | 'day' | 'hour' | 'min' | 'sec') => {
  const fromSec = count * 1000;
  const fromMin = fromSec * 60;
  const fromHour = fromMin * 60;
  const fromDay = fromHour * 24;
  const fromWeek = fromDay * 7;

  switch (from) {
    case 'sec':
      return fromSec;
    case 'min':
      return fromMin;
    case 'hour':
      return fromHour;
    case 'day':
      return fromDay;
    case 'week':
      return fromWeek;
  }
};

/**
 * Converts number of days/hours/minutes etc. to seconds
 */
export const toSeconds = (...args: Parameters<typeof toMillis>) => toMillis(...args) / 1000;

/**
 * Creates a reverse mapping for an enum-like object, where keys become values and values become keys. Helps with getting enum key by value.
 * @param enumObject An object representing an enum.
 * @returns An object with keys as values from the input enumObject and values as their respective keys.
 */
export const createReverseMappingForEnum = <T extends Record<string, string>>(
  enumObject: T,
): { [key: string]: string } =>
  Object.keys(enumObject).reduce((acc: { [key: string]: string }, key: string) => {
    const enumValue = enumObject[key as keyof T];
    acc[enumValue] = key;
    return acc;
  }, {});

/**
 * Type guard for truthy values.
 * https://stackoverflow.com/a/58110124
 */
type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;
export const truthy = <T>(value: T): value is Truthy<T> => !!value;
